import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"

import * as moment from "moment"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Pagination from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Pagination"

import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import useStoryblokDatasource from "../../src/hooks/useStoryblokDatasource"

const NewsCard = ({ item }) => {
  const { storyblokDatasourceLookup } = useStoryblokDatasource()
  return (
    <Link
      className="flex flex-col justify-between h-full shadow-md group"
      link={item.fields.full_slug_prepared}
    >
      <div className="block w-full">
        <Image
          className="object-contain w-full h-auto"
          image={item.content.image}
          aspectRatio="3by2"
        />
      </div>
      <div className="flex-1 p-4 -mt-px">
        <div>
          <div className="mb-2">
            <span className="inline-block w-3 h-3 align-baseline bg-primary"></span>
            {item.content.display_date?.length > 0 && (
              <span className="ml-4 mr-4 prose-sm">
                {moment(item.content.display_date).format("DD.MM.YYYY")}
              </span>
            )}
            <span className="font-bold prose-sm">
              {storyblokDatasourceLookup(
                "categories",
                item?.content?.categories?.[0]
              )}
            </span>
          </div>
          <span className="font-bold group-hover:text-primary">
            {item.content.title}
          </span>
          {/* <Richtext className="mt-2 prose-sm" text={item.content.teaser} /> */}
        </div>
      </div>
    </Link>
  )
}

const NewsOverviewPage = ({
  full_slug,
  content,
  news,
  language,
  numPages,
  currentPage,
  breadcrumbs,
}) => {
  let seoImage = null

  if (
    !content.image?.filename &&
    content.body &&
    content.body[0] &&
    content.body[0].image &&
    content.body[0].image.filename
  ) {
    seoImage = content.body[0].image.filename
  } else {
    seoImage = content.image?.filename
  }

  let preparedNews = news.map((n) => {
    return Object.assign({}, n, { content: JSON.parse(n.content) })
  })

  preparedNews = preparedNews.map((n) => {
    if (
      !n.content?.image?.filename &&
      n.content.body &&
      n.content.body[0] &&
      n.content.body[0].image &&
      n.content.body[0].image.filename
    ) {
      n.content.image = n.content.body[0].image
    }
    if (!n.content.image?.filename) {
      n.content.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
    }

    return n
  })

  let pagePath = full_slug

  if (pagePath[pagePath.length - 1] === "/") {
    pagePath = pagePath.slice(0, pagePath.length - 1)
  }

  const nextPage = `${pagePath}/${String(currentPage + 1)}`
  const prevPage =
    currentPage - 1 === 1
      ? `${pagePath}`
      : `${pagePath}/${String(currentPage - 1)}`

  const bodySplitIndex =
    content?.body?.findIndex(
      (block) => block.component === "news_listing_placeholder_block"
    ) || null

  let topBody = []
  let bottomBody = content.body

  if (bodySplitIndex) {
    topBody = content.body.slice(0, bodySplitIndex)
    bottomBody = content.body.slice(bodySplitIndex + 1)
  }

  let paginatedHeadline = `${content.headline}${
    currentPage !== 1 ? ": Seite " + currentPage : ""
  }`

  return (
    <Layout language={language}>
      <SEO title={paginatedHeadline} content={content} og_image={seoImage} />
      {topBody && topBody.length > 0 ? (
        <>
          {topBody[0].component !== "hero_block" && (
            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
          )}
          <BodyBlocks body={topBody} breadcrumbs={breadcrumbs} />
        </>
      ) : (
        <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      )}

      <BlockWrapper block={{}} blockWidth="content">
        <h1 className="mb-8 headline headline-h3">{paginatedHeadline}</h1>
        <div className="flex flex-row flex-wrap -mb-8 -mx-grid">
          {preparedNews.length > 0 &&
            preparedNews.map((item) => (
              <div
                className="w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                key={item.full_slug}
              >
                <NewsCard item={item} />
              </div>
            ))}
        </div>
      </BlockWrapper>

      <Pagination
        currentPage={currentPage}
        isFirstPage={currentPage === 1}
        isLastPage={currentPage === numPages}
        totalPages={numPages}
        pagePath={pagePath}
        nextPage={nextPage}
        prevPage={prevPage}
      />
      {bottomBody && bottomBody.length > 0 && <BodyBlocks body={bottomBody} />}
    </Layout>
  )
}

export default NewsOverviewPage
