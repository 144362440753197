import React from "react"
import { graphql } from "gatsby"

import NewsOverviewPage from "./NewsOverviewPage"

const NewsOverviewPageTemplate = ({ data, pageContext }) => {
  const { news, totalNews, numPages, currentPage } = pageContext
  const node = data.entry
  const content = JSON.parse(node.content)
  const parentContent = data.parentStartPage
    ? JSON.parse(data.parentStartPage.content)
    : []

  const subNavItems = pageContext.subNavData.map((subNavItem) => ({
    link: subNavItem.real_path,
    link_text: subNavItem.name,
  }))

  const breadcrumbs = pageContext.breadcrumbs
    .map((item) => ({ link: item.real_path, link_text: item.name }))
    ?.reverse()

  return (
    <NewsOverviewPage
      key={node.uid}
      content={content}
      name={node.name}
      type={content.component}
      full_slug={node.fields.full_slug_prepared}
      language={node.fields.language}
      breadcrumbs={breadcrumbs}
      subNavItems={subNavItems}
      parentContent={parentContent}
      news={news}
      totalNews={totalNews}
      numPages={numPages}
      currentPage={currentPage}
    />
  )
}

export const query = graphql`
  query NewsOverviewPageTemplateQuery($id: String!, $parentStartPageId: Int) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      fields {
        language
        full_slug_prepared
      }
    }
    parentStartPage: storyblokEntry(internalId: { eq: $parentStartPageId }) {
      content
    }
  }
`

export default NewsOverviewPageTemplate
